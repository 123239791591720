import React from 'react';

// reactstrap components
import { Row, Card, Col, CardBody, CardTitle } from 'reactstrap';

import CurrencyFormat from 'react-currency-format';

import { constructMonthlyReportArray } from '../../utils/helpers';

const MontlyTable = ({ commissions, territories, totalAggregates }) => {
  const reportCommissions = commissions.map((commission) => {
    return commission.node;
  });

  // Construct the excel export data
  const excelReportData = constructMonthlyReportArray(
    territories,
    reportCommissions,
  );

  return (
    <>
      <Row className="mt-3">
        <Col md="12">
          <div className="card-stats overview-stats mb-4">
            <CardBody className="pt-2 pb-2">
              <Row className="align-items-center justify-content-between">
                <Col md="12" lg="8" xl="8">
                  <Row className="align-items-center">
                    <div className="col border-right">
                      <div className="card-profile-stats d-flex justify-content-center p-0">
                        <div>
                          <div className="d-flex align-items-center justify-content-center ">
                            <span className="fas fa-money-bill-wave mr-1"></span>
                            <span className="description font-weight-bold">
                              Allowances
                            </span>
                          </div>
                          <span className="heading">
                            <CurrencyFormat
                              value={totalAggregates.totalAllowances}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Le '}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="card-profile-stats d-flex justify-content-center p-0">
                        <div>
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="fas fa-gifts mr-1"></span>
                            <span className="description font-weight-bold">
                              Rewards
                            </span>
                          </div>
                          <span className="heading">
                            <CurrencyFormat
                              value={totalAggregates.totalRewards}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Le '}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center p-0">
                        <div>
                          <div className="d-flex align-items-center justify-content-center ">
                            <span className="fas fa-money-bill-wave mr-1"></span>
                            <span className="description font-weight-bold">
                              Commissions
                            </span>
                          </div>
                          <span className="heading">
                            <CurrencyFormat
                              value={totalAggregates.totalCommissions}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Le '}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="card-profile-stats d-flex justify-content-center p-0">
                        <div>
                          <div className="d-flex align-items-center justify-content-center">
                            <span className="fas fa-credit-card mr-1"></span>
                            <span className="description font-weight-bold">
                              Mobile Payment Commissions
                            </span>
                          </div>
                          <span className="heading">
                            <CurrencyFormat
                              value={totalAggregates.totalPayments}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Le '}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="card-profile-stats d-flex justify-content-center p-0">
                        <div>
                          <div className="d-flex align-items-center justify-content-center ">
                            <span className="fas fa-money-bill-wave mr-1"></span>
                            <span className="description font-weight-bold">
                              PDS Total
                            </span>
                          </div>
                          <span className="heading">
                            <CurrencyFormat
                              value={totalAggregates.totalPds}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'Le '}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Col>
                <Col md="12" lg="4" xl="4" className="card-profile-stats">
                  <div className="text-right">
                    <div className="d-flex align-items-center justify-content-lg-end justify-content-center  mb-2">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description font-weight-bold">
                        Total Accrued:{' '}
                      </span>
                      <span className="heading pl-2">
                        <CurrencyFormat
                          value={totalAggregates.totalAccrued}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-lg-end justify-content-center mb-2">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description font-weight-bold">
                        Total Paid:{' '}
                      </span>
                      <span className="heading pl-2">
                        <CurrencyFormat
                          value={totalAggregates.totalPaid}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                    <div className="d-flex align-items-center justify-content-lg-end justify-content-center">
                      <span className="fas fa-money-bill-wave mr-1"></span>
                      <span className="description font-weight-bold">
                        Total Withheld:{' '}
                      </span>
                      <span className="heading pl-2">
                        <CurrencyFormat
                          value={totalAggregates.totalWithheld}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'Le '}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </div>
        </Col>
      </Row>
      <Row>
        {excelReportData.map((territoryReportData, index) => {
          return (
            <React.Fragment key={index}>
              <Col md="12" lg="6" xl="4">
                <Card className="card-stats mb-4 reports-card">
                  <CardBody>
                    <Row
                      className="justify-content-between"
                      style={{ height: 102 }}
                    >
                      <Col md="4">
                        <CardTitle
                          tag="h5"
                          className="mb-0 territory-name report-territory"
                        >
                          <p className="territory-name-short">
                            {territoryReportData.territoryAbbr}
                          </p>
                          <p className="territory-name-full text-left">
                            {territoryReportData.territoryName}
                          </p>
                        </CardTitle>
                      </Col>
                      <Col md="8" className="text-right">
                        <Row className="justify-content-end">
                          <Col className="text-right">
                            <small>Accrued:</small>
                            <small className="text-nowrap font-weight-bold pl-2">
                              <CurrencyFormat
                                value={
                                  territoryReportData.allowances +
                                  territoryReportData.commission +
                                  territoryReportData.rewards
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-right">
                            <small>Paid:</small>
                            <small className="text-nowrap font-weight-bold pl-2">
                              <CurrencyFormat
                                value={
                                  territoryReportData.allowances +
                                  territoryReportData.commission +
                                  territoryReportData.rewards -
                                  territoryReportData.withheld
                                }
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col className="text-right">
                            <small>Withheld:</small>
                            <small className="text-nowrap font-weight-bold pl-2">
                              <CurrencyFormat
                                value={territoryReportData.withheld}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr className="mb-3 mt-3" />
                    <Row className="align-items-end justify-content-between">
                      <Col md="12" className="pb-1">
                        <Row className="justify-content-between">
                          <Col>
                            <small>
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span>Allowances</span>
                            </small>
                          </Col>
                          <Col className="text-right">
                            <small className="text-nowrap font-weight-bold">
                              <CurrencyFormat
                                value={territoryReportData.allowances}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col>
                            <small>
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span>Commissions</span>
                            </small>
                          </Col>
                          <Col className="text-right">
                            <small className="text-nowrap font-weight-bold">
                              <CurrencyFormat
                                value={territoryReportData.commission}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col>
                            <small>
                              <span className="fas fa-credit-card mr-1"></span>
                              <span>Mobile Pay Com</span>
                            </small>
                          </Col>
                          <Col className="text-right">
                            <small className="text-nowrap font-weight-bold">
                              <CurrencyFormat
                                value={territoryReportData.payments}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col>
                            <small>
                              <span className="fas fa-gifts mr-1"></span>
                              <span>Rewards</span>
                            </small>
                          </Col>
                          <Col className="text-right">
                            <small className="text-nowrap font-weight-bold">
                              <CurrencyFormat
                                value={territoryReportData.rewards}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                        <Row className="justify-content-between">
                          <Col>
                            <small>
                              <span className="fas fa-money-bill-wave mr-1"></span>
                              <span>PDS</span>
                            </small>
                          </Col>
                          <Col className="text-right">
                            <small className="text-nowrap font-weight-bold">
                              <CurrencyFormat
                                value={territoryReportData.pdsAllowances}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'Le '}
                              />
                            </small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </React.Fragment>
          );
        })}
      </Row>
    </>
  );
};

export default MontlyTable;
