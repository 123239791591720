import gql from 'graphql-tag';

export const GET_ALL_LB_TERRITORIES = gql`
  query AllLbTerritories {
    allLbTerritories {
      edges {
        node {
          name
          abbreviation
          isActive
          region {
            name
          }
          lbTerriDistrict {
            districts: edges {
              node {
                name
                districtLbAgent {
                  agents: edges {
                    node {
                      username
                      role
                      agentCommission {
                        commissions: edges {
                          node {
                            agent {
                              id
                              username
                            }
                            currentWeekThursday
                            agentWeekPar
                            reward
                            isRewardPaid
                            totalRevenueMonth
                            numberOfAnticaptedSales
                            averageSalesPrice
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_LB_TERRITORY = gql`
  query GetLbTerritory($abbreviation: String!) {
    lbTerritory(abbreviation: $abbreviation) {
      id
      name
      abbreviation
      territoryManager {
        username
        firstName
        lastName
      }
      lbSupervisor {
        supervisors: edges {
          node {
            firstName
            lastName
            username
          }
        }
      }
      lbTerriDistrict {
        districts: edges {
          node {
            name
            districtLbAgent {
              agents: edges {
                node {
                  username
                  role
                  agentCommission {
                    commissions: edges {
                      node {
                        id
                        agent {
                          id
                          firstName
                          lastName
                          username
                        }
                        currentWeekThursday
                        agentWeekPar
                        consecutiveParIncrease
                        commissionOwedForTheWeek
                        weeklyOwed
                        paymentCommission
                        remitCommission
                        pdsBonus
                        pdsCommission
                        weeklyTopup
                        deductDebtFromCommission
                        commissionToDeductFromDebt
                        debtBeforeRemit
                        debtBeforeDeduction
                        totalPoints
                        weeklyPoints
                        deductDebtFromCommission
                        withholdingCommission
                        totalPayments
                        manualPayment
                        delta
                        mtnPayment
                        debtAfterRemit
                        debtAfterDeduction
                        angazaAdjustment
                        commissionOwedAfter
                        angazaAdjustementDone
                        agentOfTheMonthAndOtherRewards
                        commissionOwedBefore
                        reward
                        isRewardPaid
                        weekTotalPoints
                        amountSent
                        totalRevenueMonth
                        numberOfAnticaptedSales
                        averageSalesPrice
                        lateAccountTier
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_LB_COMMISSION = gql`
  mutation LBCommissionMutation(
    $id: ID
    $payCommission: Boolean
    $withholdingCommission: String
    $deductDebtFromCommission: Boolean
    $payload: String
  ) {
    updateLbCommission(
      id: $id
      payCommission: $payCommission
      withholdingCommission: $withholdingCommission
      deductDebtFromCommission: $deductDebtFromCommission
      payload: $payload
    ) {
      lbCommission {
        id
        payCommission
        withholdingCommission
        deductDebtFromCommission
        weeklyOwed
        commissionOwedForTheWeek
        remitCommission
        mobilePaymentCommission
        weeklyTopup
        expectedRemittance
        delta
        toAdjustOnAngaza
        commissionOwedAfter
        manualDebtAdjustments
        agentOfTheMonthAndOtherRewards
        debtAfterRemit
        debtAfterDeduction
        reward
        isRewardPaid
        totalRevenueMonth
        numberOfAnticaptedSales
        averageSalesPrice
      }
    }
  }
`;

export const GET_SINGLE_LB_AGENT_COMMISSIONS = gql`
  query GetLbAgentCommissions($agent_Username: String!, $agentview: String) {
    allLbCommissions(agent_Username: $agent_Username, agentview: $agentview) {
      edges {
        node {
          id
          agent {
            id
            firstName
            lastName
            createdUtc
            username
            userPhoto
            district {
              territory {
                name
              }
            }
            supervisor {
              firstName
              lastName
              username
              userPhoto
            }
          }
          currentWeekThursday
          expectedRemittance
          totalRemitted
          totalCashRemitted
          totalMobileMoneyRemitted
          delta
          agentTierStatus
          commissionOwedForTheWeek
          weeklyOwed
          remitCommission
          mobilePaymentCommission
          commissionOwedBefore
          weeklyTopup
          deductDebtFromCommission
          commissionToDeductFromDebt
          debtBeforeRemit
          debtBeforeDeduction
          totalPoints
          weeklyPoints
          withholdingCommission
          payCommission
          balanceFridayMorning
          productsSold
          productsUnlocked
          totalProductsSold
          totalProductsUnlocked
          agentOfTheMonthAndOtherRewards
          manualDebtAdjustments
          debtAfterRemit
          debtAfterDeduction
          totalRemittedOnTime
          yearsOfService
          previousWeekRemitScore
          consecutiveFullFiftykRemits
          consecutivePerfectFiftykRemits
          reward
          toAdjustOnAngaza
          angazaAdjustment
          commissionOwedAfter
          angazaAdjustementDone
          weekTotalPoints
          totalPayments
          manualPayment
          mtnPayment
          paymentCommission
          toSendForAgent
          amountSent
          moneySent
          lateAccountTier
          agentWeekPar
          totalRevenueMonth
          numberOfAnticaptedSales
          averageSalesPrice
        }
      }
    }
  }
`;

export const GET_CURRENT_LB_SMSES = gql`
  query getLbSmses($currentWeekThursday: String, $territoryName: String) {
    allLbSmses(
      currentWeekThursday: $currentWeekThursday
      territoryName: $territoryName
    ) {
      id
      agent {
        username
        district {
          id
          territory {
            id
            name
          }
        }
      }
      messageNumber
      textMessage
      currentWeekThursday
    }
  }
`;

export const CREATE_LB_ACTION = gql`
  mutation CreateLbAction($actionName: String, $userEmail: String) {
    addLbAction(actionName: $actionName, userEmail: $userEmail) {
      lbAction {
        id
      }
    }
  }
`;

export const GET_LB_AWAITED_REWARD = gql`
  query getLbAwaitedReward($territoryName: String, $rewarded: String) {
    allLbRewards(territoryName: $territoryName, rewarded: $rewarded) {
      id
      agent {
        id
        username
        firstName
        lastName
        primaryPhone
        agentCommission {
          edges {
            node {
              id
              totalPoints
              currentWeekThursday
            }
          }
        }
      }
      pointSystemReward {
        id
        numberOfPoint
        rewardDetail
        cash
      }
      dateJoined
      datePaid
      isCash
    }
  }
`;

export const UPDATE_LB_REWARD = gql`
  mutation UpdateLBReward($id: ID, $payload: String) {
    lbReceptionMutation(id: $id, payload: $payload) {
      lbReception {
        id
        agent {
          id
          username
          firstName
          lastName
          primaryPhone
          agentCommission {
            edges {
              node {
                id
                totalPoints
                currentWeekThursday
              }
            }
          }
        }
        pointSystemReward {
          id
          numberOfPoint
          rewardDetail
          cash
        }
        dateJoined
        datePaid
        isCash
      }
    }
  }
`;

export const GET_MONTHLY_PAYMENT_COMMISSION = gql`
  query GetMonthlyPayCommissions($week: String!, $agents: String!) {
    allLbCommissions(week: $week, agents: $agents) {
      edges {
        node {
          currentWeekThursday
          agent {
            username
          }
          commissionOwedForTheWeek
          amountSent
          toSendForAgent
        }
      }
    }
  }
`;

export const GET_SINGLE_LB_TERRITORY_SUPERVISORS = gql`
  query GetTerritory($abbreviation: String!) {
    lbTerritory(abbreviation: $abbreviation) {
      id
      name
      abbreviation
      territoryManager {
        username
        firstName
        lastName
      }
      lbSupervisor {
        supervisors: edges {
          node {
            firstName
            lastName
            username
            primaryPhone
            userPhoto
            isActive
            lbAgentSup {
              agents: edges {
                node {
                  username
                  agentCommission {
                    commissions: edges {
                      node {
                        id
                        agent {
                          id
                          firstName
                          lastName
                          username
                          balanceSll
                        }
                        currentWeekThursday
                        expectedRemittance
                        totalRemitted
                        totalCashRemitted
                        totalMobileMoneyRemitted
                        delta
                        agentTierStatus
                        commissionOwedForTheWeek
                        weeklyOwed
                        remitCommission
                        mobilePaymentCommission
                        weeklyTopup
                        deductDebtFromCommission
                        commissionToDeductFromDebt
                        debtBeforeRemit
                        debtBeforeDeduction
                        totalPoints
                        weeklyPoints
                        deductDebtFromCommission
                        withholdingCommission
                        balanceFridayMorning
                        totalPayments
                        manualPayment
                        mtnPayment
                        paymentCommission
                        toSendForAgent
                        amountSent
                        moneySent
                        lateAccountTier
                        agentWeekPar
                        totalRevenueMonth
                        numberOfAnticaptedSales
                        averageSalesPrice
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_LB_SUPERVISOR_DETAIL = gql`
  query GetSupervisorDetail($username: String!) {
    lbSupervisor(username: $username) {
      id
      firstName
      lastName
      username
      userPhoto
      primaryPhone
      lbAgentSup {
        agents: edges {
          node {
            username
            agentCommission {
              commissions: edges {
                node {
                  id
                  agent {
                    id
                    firstName
                    lastName
                    username
                    balanceSll
                  }
                  currentWeekThursday
                  expectedRemittance
                  totalRemitted
                  totalCashRemitted
                  totalMobileMoneyRemitted
                  delta
                  agentTierStatus
                  commissionOwedForTheWeek
                  weeklyOwed
                  remitCommission
                  mobilePaymentCommission
                  weeklyTopup
                  deductDebtFromCommission
                  commissionToDeductFromDebt
                  debtBeforeRemit
                  debtBeforeDeduction
                  totalPoints
                  weeklyPoints
                  deductDebtFromCommission
                  withholdingCommission
                  balanceFridayMorning
                  totalPayments
                  manualPayment
                  mtnPayment
                  paymentCommission
                  toSendForAgent
                  amountSent
                  moneySent
                  lateAccountTier
                  agentWeekPar
                  weekTotalPoints
                  debtAfterRemit
                  debtAfterDeduction
                  totalRevenueMonth
                  numberOfAnticaptedSales
                  averageSalesPrice
                }
              }
            }
          }
        }
      }
    }
  }
`;
