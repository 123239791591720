import React, { useState } from 'react'

import { Query } from 'react-apollo'
import { useQuery } from '@apollo/react-hooks';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { GET_TERRITORIES, GET_DATE_RANGED_COMMISSIONS } from "../gql/gql"
import MonthlyTable from "./monthly-table";
import { constructMonthlyReportArray, getMonthlyReportTotalAggregates, changeNumberFormat } from '../../utils/helpers';


const AgentsMonthlyReports = ({ startDate, endDate, exchanger }) => {

  const [excelReportData, setExportReportData] = useState([]);
  const [totalMonthlyAggregates, setTotalMonthlyAggreagates] = useState({
    totalAllowances: 0, totalCommissions: 0, totalRewards: 0, totalPayments: 0,
    totalPds: 0, totalAccrued: 0, totalPaid: 0, totalWithheld: 0
  })

  // Excel related info
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const formattedStart = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const formattedEnd = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short' });
  const fileName = formattedStart !== formattedEnd ? `${formattedStart} - ${formattedEnd} Report` : `${formattedStart} Report`;

  // Loading territories
  const { data, loading, error } = useQuery(GET_TERRITORIES);
  if (loading) return <p className="text-white">LOADING...</p>;
  if (error) return `Error! ${error.message}`;
  if (!data) return `NOT FOUND`;
  const territories = data.allTerritories.edges.map(territory => territory.node);

  const generateExcelReportData = (commissionEdges) => {
    const reportCommissions = commissionEdges.map(commission => {
      return commission.node;
    });
    // Construct the excel export data
    const excelData = constructMonthlyReportArray(territories, reportCommissions);
    // Update excel report data state
    setExportReportData(excelData);
    // calculate total aggregates
    const totalAggregates = getMonthlyReportTotalAggregates(excelData);
    setTotalMonthlyAggreagates(totalAggregates);
  }

  //  excel exporter to call from parent component
  const reportExcelExporter = () => {
    exportToExcel(excelReportData, fileName)

  }

  // Add the child function to exchanger that might be called by the parent.
  exchanger.reportExcelExporter = reportExcelExporter;

  const exportToExcel = (excelData, fileName) => {
    // Report period
    const start = new Date(startDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const end = new Date(endDate).toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
    const wsCols = Array.apply(1, Array(excelData.length + 4)).map(item => { return { wch: 12 } });
    // Adding 4 extra line because, the talbe start at line 3
    const wsRows = Array.apply(1, Array(excelData.length + 4)).map(item => { return { hpt: 20 } });
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: "A3" });
    ws['!cols'] = wsCols;
    ws['!rows'] = wsRows;

    // Set document title
    const title = `${start} - ${end} Report`
    ws["C1"] = { t: 's', v: title };

    // Change headers Name
    ws.A3.v = "Territory Name"; ws.B3.v = "Territory Abbreviation"; ws.C3.v = "Allowances";
    ws.D3.v = "Commissions"; ws.E3.v = "Rewards"; ws.F3.v = "Mobile Payment com"; ws.G3.v = "Total PDS"; 
    ws.H3.v = "Accrued"; ws.I3.v = "Paid"; ws.J3.v = "Withheld"

    // Getting first and last file ref which are the range
    // that will be considered in the file
    const firstRefCell = ws["!ref"].split(':')[0]
    const lastRefCell = ws["!ref"].split(':')[1]

    // knowing that the we will not go beyond Z colum
    const lastRefCellColumn = lastRefCell[0];
    const lastRefCellRow = parseInt(lastRefCell.slice(1,));
    const newRefCellRow = parseInt(lastRefCellRow) + 5;

    ws["!ref"] = `${firstRefCell}:${lastRefCellColumn + newRefCellRow}`;


    // Change number format
    changeNumberFormat(ws, ["C", "D", "E", "F", "G", "H", "I", "J"], "#,##0")

    // merge total cell A12 and B12
    const merge = [{ s: { r: lastRefCellRow, c: 0 }, e: { r: lastRefCellRow, c: 1 } }];
    ws["!merges"] = merge;

    // Set total aggregates row
    ws[`A${lastRefCellRow + 1}`] = { t: 's', v: 'Total' }; ws[`C${lastRefCellRow + 1}`] = { t: "n", f: `SUM(C4:C${lastRefCellRow})`, z: "#,##0" };
    ws[`D${lastRefCellRow + 1}`] = { t: "n", f: `SUM(D4:D${lastRefCellRow})`, z: "#,##0" }; ws[`E${lastRefCellRow + 1}`] = { t: "n", f: `SUM(E4:E${lastRefCellRow})`, z: "#,##0" };
    ws[`F${lastRefCellRow + 1}`] = { t: "n", f: `SUM(F4:F${lastRefCellRow})`, z: "#,##0" }; ws[`G${lastRefCellRow + 1}`] = { t: "n", f: `SUM(G4:G${lastRefCellRow})`, z: "#,##0" };
    ws[`H${lastRefCellRow + 1}`] = { t: "n", f: `SUM(H4:H${lastRefCellRow})`, z: "#,##0" };  ws[`I${lastRefCellRow + 1}`] = { t: "n", f: `SUM(I4:I${lastRefCellRow})`, z: "#,##0" };
    ws[`J${lastRefCellRow + 1}`] = { t: "n", f: `SUM(J4:J${lastRefCellRow})`, z: "#,##0" };

    /* Write total data */
    const wb = { Sheets: { 'Monthly Report': ws }, SheetNames: ['Monthly Report'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <div>
      {/*Normal Table*/}
      <Query query={GET_DATE_RANGED_COMMISSIONS}
        variables={{ range: `${startDate},${endDate}` }}
        fetchPolicy='no-cache'
        onCompleted={data => generateExcelReportData(data.allCommissions.edges)}
      >
        {({ loading, error, data }) => {
          if (loading) return <p className="text-white">LOADING...</p>
          if (error) {
            return <p>Error. Try refreshing the page</p>
          }

          return <MonthlyTable
            commissions={data.allCommissions.edges}
            territories={territories}
            totalAggregates={totalMonthlyAggregates} />
        }}
      </Query>
    </div>
  )
}

export default AgentsMonthlyReports
